import { ReactElement } from 'react';

import { Translate } from '@cms/i18n';
import { News, Platform, PlatformID } from '@common/clients/api';
import { Injection } from '@web/molecules/NewsList';

export const getMoreNewsButtonText = (items: News[] = [], platform: Platform, __translate: Translate) => {
    const hasItems = items && items.length > 0;
    if (!hasItems) {
        return '';
    }

    const moreVideosButtonText = __translate('more-videos');
    const moreArticlesButtonText =
        platform.id === PlatformID.GP || platform.id === PlatformID.BR
            ? __translate('more-news')
            : __translate('more-articles');

    const isMediaNewsList = items.every((item) => item?.category?.isMedia);
    return isMediaNewsList ? moreVideosButtonText : moreArticlesButtonText;
};

export const isInjection = (value: ReactElement | Injection): value is Injection =>
    typeof value === 'object' && value !== null && 'element' in value;
