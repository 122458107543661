import { RefObject } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { News, Platform, PlatformID, Tag } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import {
    DateTimeUtil,
    Format,
    formatDuration,
    formatNumericDuration,
    Perspective,
} from '@common/utils/DateTimeUtil';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import { DisplayType } from '../config/types';
import { Flag } from '../Flag';
import { NewsBlock } from '../NewsBlock';
import { NewsBlockHorizontal } from '../NewsBlockHorizontal';
import { getNewsItemClasses } from './getNewsItemClasses';

import styles from './NewsListItem.module.scss';

interface Props {
    newsItem: News;
    displayType?: DisplayType;
    isDossier?: boolean;
    firstElementRef?: RefObject<HTMLLIElement>;
    tag?: Tag;
}

const renderNewsBlock = (isListType: boolean, newsItem: News) => {
    if (isListType) {
        return <NewsBlockHorizontal item={newsItem} />;
    }

    return <NewsBlock item={newsItem} />;
};

const shouldRenderNewsBlock = (platform: Platform) => {
    return platform.id === PlatformID.VI;
};

interface LegacyBlockProps {
    displayType?: DisplayType;
    isDossier?: boolean;
    newsItem: News;
    tag?: Tag;
}

const LegacyBlock = ({ displayType, isDossier, newsItem, tag }: LegacyBlockProps) => {
    const { platform, context } = useContextData();
    const isMini = displayType === DisplayType.compact || displayType === DisplayType.list;
    const isBold = newsItem?.titleIsBold;
    const imageSize = isMini ? WebpWidthEnum.WIDTH_110 : WebpWidthEnum.WIDTH_225;
    const __datetime = useTranslation('datetime').t;

    const date: Date = new Date(newsItem.newsDate);
    let path: string = '';
    if (newsItem.path) {
        if (newsItem.host && !newsItem.host.match(context.url)) {
            path = newsItem.host;
        }
        path += newsItem.path;
    }

    return (
        <>
            {displayType === DisplayType.compact ? (
                <>
                    {isDossier ? (
                        <span className={styles.radio} />
                    ) : (
                        <>
                            <PlatformToggle include={[PlatformID.VP, PlatformID.VN]}>
                                <Flag
                                    displayType={displayType}
                                    tag={newsItem.tags?.at(0)}
                                    platform={platform.id}
                                />
                            </PlatformToggle>
                            <span className={styles.time} suppressHydrationWarning={true}>
                                {newsItem?.newsVideo ? <Icon.play /> : null}
                                {DateTimeUtil.format(date, Format.TIME_NUMERIC)}
                            </span>
                        </>
                    )}
                </>
            ) : null}
            {displayType !== DisplayType.compact ? (
                <Link href={path}>
                    <div className={styles['play-video']}>
                        {newsItem.category?.isMedia && !isMini ? (
                            <>
                                <PlatformToggle exclude={PlatformID.VI}>
                                    <span className={'play-button'}>
                                        <span className="icon"></span>
                                    </span>
                                </PlatformToggle>
                                {newsItem?.videofeedItem?.duration ? (
                                    <span className={styles.time}>
                                        {formatNumericDuration(newsItem.videofeedItem.duration)}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        <WebpImage
                            defaultSize={imageSize}
                            className={styles.image}
                            src={newsItem.image || ''}
                            alt={''}
                            ratio={110 / 60}
                        />
                    </div>
                </Link>
            ) : null}
            {!isMini ? (
                <div className={styles['tag-container']}>
                    <p>{newsItem.category?.name}</p>
                </div>
            ) : null}
            <div className={isDossier ? styles.content + ' content' : styles.content}>
                <Link href={path}>
                    <h4 className={isBold ? styles['extra-bold'] : ''}>
                        <div className={styles['title-wrapper']}>
                            <TagElement item={newsItem} displayType={displayType} tag={tag} />
                            <p className={styles.title}>{newsItem.newsTitle}</p>
                        </div>
                    </h4>
                </Link>
                <>
                    <div className={styles.meta}>
                        <span className={styles['long-date']} suppressHydrationWarning={true}>
                            {formatDuration({
                                date,
                                __translate: __datetime,
                                perspective: Perspective.IN_AGO,
                            })}
                        </span>
                        {newsItem.commentCount ? (
                            <Link href={path + '#comments_anchor'}>
                                <div className={styles.comments}>
                                    <Icon.comment />
                                    <span className={styles['comment-count']}>{newsItem.commentCount}</span>
                                </div>
                            </Link>
                        ) : null}
                    </div>
                </>
            </div>
        </>
    );
};

LegacyBlock.displayName = 'NewsListItemLegacyBlock';

export const NewsListItem = ({ newsItem, displayType, isDossier, firstElementRef, tag }: Props) => {
    const { platform } = useContextData();
    const isBlockType = displayType === DisplayType.blocks || displayType === DisplayType.carrousel;
    const isListType = displayType === DisplayType.list;
    const { classes, dataItems } = getNewsItemClasses({
        displayType,
        isDossier,
        category: newsItem.category,
        isBlockType,
    });

    return (
        <li className={classes.join(' ')} ref={firstElementRef} {...dataItems}>
            {shouldRenderNewsBlock(platform) ? (
                renderNewsBlock(isListType, newsItem)
            ) : (
                <LegacyBlock displayType={displayType} isDossier={isDossier} newsItem={newsItem} tag={tag} />
            )}
        </li>
    );
};

NewsListItem.displayName = 'NewsListItem';
