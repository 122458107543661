export enum DisplayType {
    compact = 'compact',
    list = 'list',
    blocks = 'blocks',
    carrousel = 'carrousel',
}

export interface NewsListThemeConfigProps {
    showIconTabs: boolean;
    skipDivider: boolean;
    displayType: DisplayType;
    mobileDisplayType?: DisplayType;
    displayTypeSwitchOptions?: DisplayType[];
}
