import { useCallback, useEffect, useState } from 'react';

import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';

import { DisplayType } from '../config/types';

interface UseDisplayTypeProps {
    initialDisplayType: DisplayType;
    mobileDisplayType?: DisplayType;
}

export const useDisplayType = ({ initialDisplayType, mobileDisplayType }: UseDisplayTypeProps) => {
    const [displayType, setDisplayType] = useState<DisplayType>(initialDisplayType);

    const isMobile = useResponsive({
        direction: MediaQueryDirection.below,
        size: MediaQuery.m,
    });

    const updateDisplayType = useCallback((newDisplayType: DisplayType) => {
        setDisplayType((prev) => {
            if (prev !== newDisplayType) {
                return newDisplayType;
            }
            return prev;
        });
    }, []);

    useEffect(() => {
        if (isMobile && mobileDisplayType) {
            setDisplayType(mobileDisplayType);
        } else if (!isMobile && initialDisplayType) {
            setDisplayType(initialDisplayType);
        }
    }, [isMobile, mobileDisplayType, initialDisplayType]);

    return {
        displayType,
        setDisplayType: updateDisplayType,
    };
};
