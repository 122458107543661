import { Link } from '@common/atoms/Link';
import { News } from '@common/clients/api';
import { NewsFunctionality } from '@common/types/NewsFunctionality';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { formatNumericDuration } from '@common/utils/DateTimeUtil';
import { ProTag } from '@web/atoms/ProTag';
import { Time, TimeStyle } from '@web/atoms/Time';
import { WebpImage } from '@web/atoms/WebpImage';

import styles from './NewsBlock.module.scss';

enum AspectRatio {
    BLOCK_THUMB = 110 / 60,
}

interface Props {
    item: News;
    isMobile?: boolean;
}

export const NewsBlock = ({ item: { newsTitle, category, image, videofeedItem, host, path } }: Props) => {
    const isProArticle = category?.functionality === NewsFunctionality.PRO;
    const url = host && path ? host + path : '';
    const videoDuration = videofeedItem?.duration ? formatNumericDuration(videofeedItem.duration) : null;

    return (
        <Link href={url} className={`${styles.NewsBlock} ${isProArticle ? styles.pro : ''}`}>
            <div className={styles['image-container']}>
                <WebpImage
                    defaultSize={WebpWidthEnum.WIDTH_225}
                    src={image}
                    alt={''}
                    className={styles.image}
                    ratio={AspectRatio.BLOCK_THUMB}
                />
                <div className={`${styles.overlay} ${isProArticle ? styles['overlay-gradient'] : ''}`}>
                    {videoDuration ? <Time styleVariant={TimeStyle.DURATION} time={videoDuration} /> : null}
                    {isProArticle ? <ProTag tag={category?.name || ''} /> : null}
                </div>
            </div>
            <div className={styles['title']}>{newsTitle ? <h4>{newsTitle}</h4> : null}</div>
        </Link>
    );
};
