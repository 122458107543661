import { ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

import { useDisplayType } from '../hooks/useDisplayType';
import { DisplayType, NewsListThemeConfigProps } from './types';

export const THEME_CONFIG: ThemeConfig<NewsListThemeConfigProps> = {
    defaults: {
        showIconTabs: true,
        skipDivider: false,
        displayType: DisplayType.list,
        displayTypeSwitchOptions: [DisplayType.compact, DisplayType.list],
    },
    byPlatform: {
        vi: {
            showIconTabs: false,
            skipDivider: true,
            displayType: DisplayType.blocks,
            mobileDisplayType: DisplayType.list,
            displayTypeSwitchOptions: [],
        },
        vp: {
            displayType: DisplayType.compact,
            displayTypeSwitchOptions: [DisplayType.compact, DisplayType.list],
        },
        vn: {
            displayType: DisplayType.compact,
            displayTypeSwitchOptions: [DisplayType.compact, DisplayType.list],
        },
    },
};

export interface NewsListConfigProps {
    displayType?: DisplayType;
    displayTypeSwitchOptions?: DisplayType[];
    showContentAsTabs?: boolean;
    showIconTabs?: boolean;
}

/**
 * Hook to get news list theme configuration with optional block display override
 * @param showContentAsTabs - When true, forces content to display as blocks regardless of platform settings
 * @returns Theme configuration with potentially modified display type
 */

export const useNewsListThemeConfig = (props?: NewsListConfigProps) => {
    const themeConfig = useThemeConfig(THEME_CONFIG);

    // Determine initial display type in the following order:
    // 1. Component displayType prop
    // 2. First item in displayTypeSwitchOptions
    // 3. Blocks if showContentAsTabs is true
    // 4. Theme config display type
    const initialDisplayType =
        props?.displayType ??
        props?.displayTypeSwitchOptions?.[0] ??
        (props?.showContentAsTabs ? DisplayType.blocks : themeConfig.displayType);

    const { displayType, setDisplayType } = useDisplayType({
        initialDisplayType,
        mobileDisplayType: themeConfig.mobileDisplayType,
    });

    // Override theme config if provided in props
    const displayTypeSwitchOptions = props?.displayTypeSwitchOptions ?? themeConfig.displayTypeSwitchOptions;
    const showIconTabs = props?.showIconTabs ?? themeConfig.showIconTabs;

    return {
        ...themeConfig,
        displayType,
        setDisplayType,
        displayTypeSwitchOptions,
        showIconTabs,
    };
};
