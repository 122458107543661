import { FC } from 'react';

import { ShowMoreLink } from '@web/atoms/ShowMoreLink/ShowMoreLink';
import { IconTabs, Tab } from '@web/molecules/IconTabs';

import { DisplayType } from '../config/types';

import styles from './Divider.module.scss';

interface Props {
    title?: string | JSX.Element;
    className?: string;
    hasBorder?: boolean;
    isEmphasized?: boolean;
    showIconTabs?: boolean;
    iconTabs?: Tab[];
    onIconTabClick?: (type: DisplayType) => void;
    moreArticlesLink?: {
        text: string;
        url: string;
        isVisible: boolean;
    };
    displayType?: DisplayType;
}

export const Divider: FC<Props> = ({
    className,
    displayType,
    hasBorder = true,
    iconTabs,
    isEmphasized,
    moreArticlesLink,
    onIconTabClick,
    showIconTabs,
    title,
}) => {
    return (
        <li
            className={`${styles.Divider} ${hasBorder ? styles['border'] : styles['no-border']}
            ${isEmphasized ? styles['emphasized'] : ''} ${className}`}
        >
            {title ? title : null}
            {moreArticlesLink?.isVisible ? (
                <ShowMoreLink href={moreArticlesLink.url} showMoreText={moreArticlesLink.text} />
            ) : null}
            {showIconTabs && iconTabs && onIconTabClick ? (
                <IconTabs tabs={iconTabs} activeTab={displayType} onIconTabClick={onIconTabClick} />
            ) : null}
        </li>
    );
};
